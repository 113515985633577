export function formatDateWithOrdinal(date) {
    const options = { year: 'numeric', month: 'long' };
    const day = date.getDate();
    let daySuffix;

    switch (day) {
        case 1:
        case 21:
        case 31:
            daySuffix = 'st';
            break;
        case 2:
        case 22:
            daySuffix = 'nd';
            break;
        case 3:
        case 23:
            daySuffix = 'rd';
            break;
        default:
            daySuffix = 'th';
    }

    return `${day}${daySuffix} ${date.toLocaleDateString('en-GB', options)}`;
}