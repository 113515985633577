import React from 'react';
import { createBrowserRouter, RouterProvider, redirect } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Properties from './pages/Properties';
import CreateProperty from './pages/CreateProperty'
import PropertySingle from './pages/PropertySingle';
import CreateTenant from './pages/CreateTenant';
import TenantSingle from './pages/Tenants'
import Register from './pages/Register';
import { getUser } from './services/AuthService';
import ViewTenancyAgreement from './pages/TenancyAgreement';
import ViewDocuments from './pages/ViewId';

const router = createBrowserRouter([
  {
    path: '/',
    loader: async () => {
      const user = await getUser();
      if (user) {
        return redirect('/properties');
      } else {
        return redirect('/login');
      }
    },
    element: null, // No component needed since it's just a redirect
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/properties',
    element: <Properties />,
    // loader: async () => {
    //   const user = await getUser();
    //   if (!user) {
    //     return redirect('/login');
    //   }
    //   return null;
    // },
  },
  {
    path: '/properties/:id',
    element: <PropertySingle />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
  {
    path: '/properties/:id/add-tenant',
    element: <CreateTenant />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
  {
    path: '/properties/:id/:tenantid',
    element: <TenantSingle />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
  {
    path: '/properties/:id/:tenantid/proof-documents',
    element: <ViewDocuments />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
  {
    path: '/properties/:id/:tenantid/tenancy-agreement',
    element: <ViewTenancyAgreement />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
  {
    path: '/add-new-property',
    element: <CreateProperty />,
    loader: async () => {
      const user = await getUser();
      if (!user) {
        return redirect('/login');
      }
      return null;
    },
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;