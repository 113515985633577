import React from "react";
import PropTypes from "prop-types";
import Navbar from "./Navbar";
import Breadcrumb from "../components/Breadcrumbs"; // Import the Breadcrumb component
import { Helmet } from "react-helmet";
import Loading from '../pages/Loading';

const Main = ({
  pageClass = '',
  children,
  pageTitle,
  bgColour = '#ffffff',
  loading = false,
  metaDescription = '',
  metaKeywords = ''
}) => {
  return (
    <div className={`${pageClass} page-class`} style={bgColour ? { background: bgColour } : {}}>
      <Helmet>
        <title>{`${pageTitle} | Diar`}</title>
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      </Helmet>
      <div className="page-content">
        <Navbar />
        <div className="container">
          <Breadcrumb />
        </div>
        <div className="content-wrapper">
          {loading ? (
            <Loading />
          ) : (
            children
          )}
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  pageClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  bgColour: PropTypes.string,
  loading: PropTypes.bool,
  metaDescription: PropTypes.string,
  metaKeywords: PropTypes.string
};

export default Main;