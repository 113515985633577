import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Main from '../components/Main';

function ViewDocuments({ tenantId }) {

    const { tenantid } = useParams();
    const [documents, setDocuments] = useState({
        proofOfIdentification: null,
        bankStatements: null,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                // Make a request to get tenant information including file paths
                const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/${tenantid}/documents`);
                console.log(response)
                const tenant = response.data;

                // Assuming the backend sends the full file path with the correct filename
                setDocuments({
                    proofOfIdentification: tenant.proofOfIdentification ? `http://${process.env.REACT_APP_URL}/uploads/${tenant.proofOfIdentification}` : null,
                    bankStatements: tenant.bankStatements ? `http://${process.env.REACT_APP_URL}/uploads/${tenant.bankStatements}` : null,
                });

                setLoading(false);
            } catch (error) {
                console.error('Error fetching documents:', error);
                setLoading(false);
            }
        };

        fetchDocuments();
    }, [tenantId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <Main pageTitle="Proof of ID">
            <div className='container'>
            <h2>View Documents</h2>
            {documents.proofOfIdentification ? (
                <div>
                    <h3>Proof of Identification</h3>
                    {documents.proofOfIdentification.endsWith('.pdf') ? (
                        <iframe
                            src={documents.proofOfIdentification}
                            title="Proof of Identification"
                            width="100%"
                            height="600px"
                        ></iframe>
                    ) : (
                        <img
                            src={documents.proofOfIdentification}
                            alt="Proof of Identification"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    )}
                </div>
            ) : (
                <p>No proof of identification uploaded.</p>
            )}

            {documents.bankStatements ? (
                <div>
                    <h3>Bank Statements</h3>
                    {documents.bankStatements.endsWith('.pdf') ? (
                        <iframe
                            src={documents.bankStatements}
                            title="Bank Statements"
                            width="100%"
                            height="600px"
                        ></iframe>
                    ) : (
                        <img
                            src={documents.bankStatements}
                            alt="Bank Statements"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    )}
                </div>
            ) : (
                <p>No bank statements uploaded.</p>
            )}
        </div>
        </Main>
    );
}

export default ViewDocuments;