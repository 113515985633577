import React from 'react';
import '../assets/scss/SuccessModal.scss';

const SuccessModal = ({ message, onClose }) => {
  return (
    <div className="success-modal-overlay">
      <div className="success-modal">
        <h2>Success</h2>
        <p>{message}</p>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default SuccessModal;