import React from 'react';
import axios from 'axios';
import '../assets/scss/RentSchedule.scss';

const RentScheduleTable = ({ payments, tenantId, onPaymentUpdate }) => {

  const handleMarkAsPaid = async (paymentIndex) => {
    console.log(paymentIndex)
    try {
      await axios.post(`http://${process.env.REACT_APP_URL}/api/tenants/payments/${tenantId}/process-payment`, { paymentIndex });
      // Update the UI or state after the API call is successful
      onPaymentUpdate(paymentIndex, 'paid');
    } catch (err) {
      console.error('Error marking payment as paid:', err);
    }
  };

  return (
    <table className="rent-schedule-table">
      <thead>
        <tr>
          <th>Payment Date</th>
          <th>Status</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment, index) => {
          const dateDue = new Date(payment.dateDue);
          const nextMonthDate = new Date(dateDue);
          nextMonthDate.setMonth(dateDue.getMonth() + 1);
          nextMonthDate.setDate(dateDue.getDate() - 1);

          return (
            <tr key={index}>
              <td>
                {`${dateDue.toLocaleDateString('en-GB')} - ${nextMonthDate.toLocaleDateString('en-GB')}`}
              </td>
              <td>
                {payment.status} {payment.datePaid && `on ${new Date(payment.datePaid).toLocaleDateString('en-GB')}`}
              </td>
              <td>£{payment.amount.toFixed(2)}</td>
              <td>
                {payment.status !== 'paid' && (
                  <button onClick={() => handleMarkAsPaid(index)}>Mark as Paid</button>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RentScheduleTable;