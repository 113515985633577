import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../assets/scss/Properties.scss';
import Main from '../components/Main';
import { Link } from 'react-router-dom';
import House from "../assets/images/icons/home.svg";
import Loading from './Loading';

const Properties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({
    managedBy: '',
    occupancy: ''
  });

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/properties`);
        setProperties(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching properties:', err);
      }
    };
    fetchProperties();
  }, []);

  const filteredProperties = properties.filter(property => {
    return (
      (filter.managedBy ? property.managedBy === filter.managedBy : true) &&
      (filter.occupancy === 'occupied' ? property.tenant : filter.occupancy === 'unoccupied' ? !property.tenant : true)
    );
  });

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Main pageTitle="Properties">
      <div className='properties-page'>
        <div className="properties-page-container container">
          <h1>Properties</h1>

          <a href='/add-new-property'>
            <button className='add-new-property'>Add new property</button>
          </a>

          <PropertiesFilter filter={filter} setFilter={setFilter} />

          <div className='properties-container'>
            {filteredProperties.length > 0 ? (
              filteredProperties.map((property) => (
                <PropertyCard key={property._id} property={property} />
              ))
            ) : (
              <p>No properties found</p>
            )}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Properties;


const PropertyCard = ({ property }) => {
  const occupiedClass = property.tenant ? 'occupied' : '';

  return (
    <Link to={`/properties/${property._id}`} className={`property-card ${occupiedClass}`}>
      <img src={House} alt='house-icon' width={50} />
      <p className='nickname'>{property.nickname}</p>
      <p>{property.address1}</p>
      {property.address2 && <p>{property.address2}</p>}
      <p>{property.city}</p>
      <p>{property.postcode}</p>
      <p>Managed by {property.managedBy}</p>
    </Link>
  );
};

const PropertiesFilter = ({ filter, setFilter }) => {

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({
      ...prevFilter,
      [name]: value
    }));
  };

  return (
    <div className="filters">
      <select name="managedBy" value={filter.managedBy} onChange={handleFilterChange}>
        <option value="">All Managers</option>
        <option value="Diar">Managed by Diar</option>
        <option value="Agent">Managed by Agent</option>
      </select>

      <select name="occupancy" value={filter.occupancy} onChange={handleFilterChange}>
        <option value="">All Properties</option>
        <option value="occupied">Occupied</option>
        <option value="unoccupied">Unoccupied</option>
      </select>
    </div>
  );
};