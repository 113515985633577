import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Loading from './Loading';

const ViewTenancyAgreement = () => {
  const { tenantid } = useParams();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTenancyAgreement = async () => {
      try {
        const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/documents/${tenantid}/generate-tenancy-agreement`);
        setPdfUrl(response.data.pdfUrl);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching tenancy agreement:', err);
        setLoading(false);
      }
    };

    fetchTenancyAgreement();
  }, [tenantid]);

  const handleSend = async () => {
    try {
      await axios.post(`/api/tenants/${tenantid}/send-tenancy-agreement`);
      alert('Tenancy agreement sent successfully!');
    } catch (err) {
      console.error('Error sending tenancy agreement:', err);
    }
  };

  const handleSave = () => {
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `Tenancy_Agreement_${tenantid}.pdf`;
    link.click();
  };

  const handlePrint = () => {
    window.open(pdfUrl, '_blank');
  };

  if (loading) return <Loading />;

  return (
    <div>
      <h1>Tenancy Agreement</h1>
      {pdfUrl && (
        <div>
          <iframe title='tenancy-agreement' src={pdfUrl} width="100%" height="600px"></iframe>
          <div>
            <button onClick={handleSend}>Send</button>
            <button onClick={handleSave}>Save</button>
            <button onClick={handlePrint}>Print</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewTenancyAgreement;