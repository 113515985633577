import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Main from '../components/Main';
import '../assets/scss/CreateTenant.scss';
import Loading from './Loading';
import ErrorModal from '../components/ErrorModal';

const CreateTenant = () => {
  const { id } = useParams(); // Get the property ID from the URL
  const [tenantData, setTenantData] = useState({
    name: '',
    phone: '',
    email: '',
    nextOfKin: {
      name: '',
      relationship: '',
      address: '',
      phone: '',
      email: ''
    },
    tenancyStartDate: '',
    rentAmount: 0,
    passportNumber: '',
    nationalInsuranceNumber: '',
    proofOfIdentification: null,
    bankStatements: []
  });
  const [property, setProperty] = useState(null);
  const [tenants, setTenants] = useState([]);
  const [error, setError] = useState(null);
  const [addNok, setAddNok] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPropertyAndTenants = async () => {
      try {
        const propertyResponse = await axios.get(`http://${process.env.REACT_APP_URL}/api/properties/${id}`);
        setProperty(propertyResponse.data);

        const tenantsResponse = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/property/${id}`);
        setTenants(tenantsResponse.data);

      } catch (err) {
        console.error('Error fetching property or tenants:', err);
        setError('Error fetching property or tenants.');
      }
    };

    fetchPropertyAndTenants();
  }, [id]);

  const onDrop = (acceptedFiles, rejectedFiles, fieldName) => {
    if (fieldName === 'proofOfIdentification') {
      setTenantData({ ...tenantData, proofOfIdentification: acceptedFiles[0] });
    } else if (fieldName === 'bankStatements') {
      if (tenantData.bankStatements.length + acceptedFiles.length <= 2) {
        setTenantData({ ...tenantData, bankStatements: [...tenantData.bankStatements, ...acceptedFiles] });
      } else {
        setError('You can only upload a total of 2 bank statements.');
      }
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const deleteFile = (fieldName, index = null) => {
    if (fieldName === 'proofOfIdentification') {
      setTenantData({ ...tenantData, proofOfIdentification: null });
    } else if (fieldName === 'bankStatements') {
      const updatedBankStatements = tenantData.bankStatements.filter((_, i) => i !== index);
      setTenantData({ ...tenantData, bankStatements: updatedBankStatements });
    }
  };

  const { getRootProps: getProofRootProps, getInputProps: getProofInputProps, isDragActive: isProofDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles, 'proofOfIdentification'),
    accept: 'image/*,application/pdf',
    multiple: false
  });

  const { getRootProps: getBankRootProps, getInputProps: getBankInputProps, isDragActive: isBankDragActive } = useDropzone({
    onDrop: (acceptedFiles, rejectedFiles) => onDrop(acceptedFiles, rejectedFiles, 'bankStatements'),
    accept: 'application/pdf',
    multiple: true
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.includes('.')) {
      const [nestedKey, nestedField] = name.split('.');
      setTenantData(prevState => ({
        ...prevState,
        [nestedKey]: {
          ...prevState[nestedKey],
          [nestedField]: value
        }
      }));
    } else {
      setTenantData({
        ...tenantData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tenantData.bankStatements.length !== 2) {
      setError('You must upload exactly 2 bank statements.');
      return;
    }

    const formData = new FormData();

    Object.keys(tenantData).forEach(key => {
      if (key === 'nextOfKin') {
        Object.keys(tenantData.nextOfKin).forEach(nestedKey => {
          formData.append(`nextOfKin.${nestedKey}`, tenantData.nextOfKin[nestedKey]);
        });
      } else if (key === 'proofOfIdentification') {
        if (tenantData.proofOfIdentification) {
          formData.append(key, tenantData.proofOfIdentification);
        }
      } else if (key === 'bankStatements') {
        tenantData.bankStatements.forEach((file, index) => {
          if (file) {
            formData.append('bankStatements', file); // Append all bank statements under the same key
          }
        });
      } else {
        formData.append(key, tenantData[key]);
      }
    });

    // Log formData entries for debugging
    for (let pair of formData.entries()) {
      console.log(pair[0]+ ': ' + pair[1]); 
    }

    try {
      const response = await axios.post(`http://${process.env.REACT_APP_URL}/api/tenants/create/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Tenant added:', response.data);
      navigate(`/properties/${id}`);
    } catch (err) {
      console.error('Error adding tenant:', err);
      setError('Error adding tenant.');
    }
  };

  if (!property) return <Loading />;

  return (
    <Main pageTitle="Add new tenant">
      <div className="create-tenant-page">
        <h1 className='font-medium'>Add New Tenant to {property.nickname}</h1>
        <form onSubmit={handleSubmit} className="tenant-form">
          <div className="box">
            <h2 className='font-light mt-zero mb-md'>Tenant's Details</h2>
            <div className="form-group">
              <label htmlFor="name">Tenant Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={tenantData.name}
                onChange={handleChange}
                required
              />
            </div>
            {/* Add fields for passport number and national insurance number */}
            <div className="form-group">
              <label htmlFor="passportNumber">Passport Number</label>
              <input
                type="text"
                id="passportNumber"
                name="passportNumber"
                value={tenantData.passportNumber}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="nationalInsuranceNumber">National Insurance Number</label>
              <input
                type="text"
                id="nationalInsuranceNumber"
                name="nationalInsuranceNumber"
                value={tenantData.nationalInsuranceNumber}
                onChange={handleChange}
                required
              />
            </div>

            {/* File uploads with drag and drop */}
            <div className="form-group">
              <label htmlFor="proofOfIdentification">Proof of Identification</label>
              <div {...getProofRootProps({ className: `dropzone ${isProofDragActive ? 'dragging' : ''}` })}>
                <input {...getProofInputProps()} />
                <p>Drag 'n' drop a file here, or click to select a file</p>
                {tenantData.proofOfIdentification && (
                  <div className="uploaded-file">
                    <i className="file-icon"></i>
                    <strong>{tenantData.proofOfIdentification.name}</strong>
                    <button type="button" onClick={() => deleteFile('proofOfIdentification')} className="delete-btn">Delete</button>
                  </div>
                )}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="bankStatements">2 Months Bank Statements</label>
              <div {...getBankRootProps({ className: `dropzone ${isBankDragActive ? 'dragging' : ''}` })}>
                <input {...getBankInputProps()} />
                <p>Drag 'n' drop exactly 2 files here, or click to select files</p>
                {tenantData.bankStatements && tenantData.bankStatements.map((file, index) => (
                  <div key={index} className="uploaded-file">
                    <i className="file-icon"></i>
                    <strong>{file.name}</strong>
                    <button type="button" onClick={() => deleteFile('bankStatements', index)} className="delete-btn">Delete</button>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={tenantData.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={tenantData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Next of Kin Section */}
          <div className="box">

            {addNok ? (
              <>
                <div className="nokBox">
                  <h2 className='font-light m-zero'>Next of Kin Details</h2>
                  <div className="form-group">
                    <label className='ios-switch' htmlFor="addNok">
                      <input
                        type="checkbox"
                        id="addNok"
                        value={addNok}
                        onChange={() => setAddNok(!addNok)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="nokName">Name</label>
                  <input
                    type="text"
                    id="nokName"
                    name="nextOfKin.name"
                    value={tenantData.nextOfKin.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="nokRelationship">Relationship</label>
                  <input
                    type="text"
                    id="nokRelationship"
                    name="nextOfKin.relationship"
                    value={tenantData.nextOfKin.relationship}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="nokAddress">Address</label>
                  <input
                    type="text"
                    id="nokAddress"
                    name="nextOfKin.address"
                    value={tenantData.nextOfKin.address}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="nokPhone">Phone</label>
                  <input
                    type="text"
                    id="nokPhone"
                    name="nextOfKin.phone"
                    value={tenantData.nextOfKin.phone}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="nokEmail">Email</label>
                  <input
                    type="email"
                    id="nokEmail"
                    name="nextOfKin.email"
                    value={tenantData.nextOfKin.email}
                    onChange={handleChange}
                  />
                </div>
              </>
            ) : (
              <div className="nokBox">
                <h2 className='font-light m-zero'>Next of Kin Details</h2>
                <div className="form-group">
                  <label className='ios-switch' htmlFor="addNok">
                    <input
                      type="checkbox"
                      id="addNok"
                      value={addNok}
                      onChange={() => setAddNok(!addNok)}
                    />
                    <span className="slider"></span>
                  </label>
                </div>
              </div>
            )}
          </div>

          <h2 className="font-light m-zero">Tenancy Details</h2>
          <div className="box">
            <div className="form-group">
              <label htmlFor="tenancyStartDate">Tenancy Start Date</label>
              <input
                type="date"
                id="tenancyStartDate"
                name="tenancyStartDate"
                value={tenantData.tenancyStartDate}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="rentAmount">Rent Amount</label>
              <input
                type="number"
                id="rentAmount"
                name="rentAmount"
                value={tenantData.rentAmount}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <button type="submit" className="submit-btn">Add Tenant</button>
        </form>
      </div>
      {error && <ErrorModal message={error} onClose={handleCloseError} />}
    </Main>
  );
};

export default CreateTenant;