import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader';

const Loading = () => {
    return (
        <div className="loading-page">
        <ClipLoader
          color="#242254"
          size={60}
        />
      </div>
    )
}

export default Loading