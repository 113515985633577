import React, { useState } from 'react';
import Main from '../components/Main';
import axios from 'axios';
import '../assets/scss/CreateProperty.scss'
import { useNavigate } from 'react-router-dom'

const CreateProperty = () => {
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const [propertyData, setPropertyData] = useState({
    nickname: '',
    address1: '',
    address2: '',
    city: '',
    postcode: '',
    managedBy: '', // Initialize with empty string
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPropertyData({
      ...propertyData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate form data
    if (propertyData.nickname && propertyData.address1 && propertyData.postcode && propertyData.managedBy) {
      try {
        await axios.post(`http://${process.env.REACT_APP_URL}/api/properties/create`, propertyData);
        navigate('/properties'); // Redirect to properties page after successful creation
      } catch (err) {
        setError(err.response?.data?.message || 'Creation failed');
      }
    } else {
      console.log('Please fill in all required fields');
    }
  };

  return (
    <Main pageTitle="Create new property">
      <div className="new-property container-small">
        <h1>Add new property</h1>
        <form onSubmit={handleSubmit} className="property-form">
          {error && <p className="error-message">{error}</p>}
          <div className="form-group">
            <label htmlFor="nickname">Name</label>
            <input
              type="text"
              id="nickname"
              name="nickname"
              value={propertyData.nickname}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="address1">Address Line 1</label>
            <input
              type="text"
              id="address1"
              name="address1"
              value={propertyData.address1}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="address2">Address Line 2</label>
            <input
              type="text"
              id="address2"
              name="address2"
              value={propertyData.address2}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={propertyData.city}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="postcode">Postcode</label>
            <input
              type="text"
              id="postcode"
              name="postcode"
              value={propertyData.postcode}
              onChange={handleChange}
              required
            />
          </div>

          {/* Managed By Dropdown */}
          <div className="form-group">
            <label htmlFor="managedBy">Managed By</label>
            <select
              id="managedBy"
              name="managedBy"
              value={propertyData.managedBy}
              onChange={handleChange}
              required
            >
              <option value="">Select Manager</option>
              <option value="Diar">Diar</option>
              <option value="Agent">Agent</option>
            </select>
          </div>

          <div className="submit-btn">
            <button type="submit">
              Create Property
            </button>
          </div>
        </form>
      </div>
    </Main>
  );
};

export default CreateProperty;