import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import Main from '../components/Main';
import '../assets/scss/Tenants.scss';
import RentScheduleTable from '../components/RentSchedule';
import * as XLSX from 'xlsx'; // For Excel export
import ClipLoader from 'react-spinners/ClipLoader';
import Loading from './Loading';
import SuccessModal from '../components/SuccessModal';
import ErrorModal from '../components/ErrorModal';
import {formatDateWithOrdinal} from '../services/utilities'

const TenantSingle = () => {
  const { tenantid } = useParams();
  const [tenant, setTenant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [actionComplete, setActionComplete] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [proRata, setProRata] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signature, setSignature] = useState(null);
  const signatureCanvasRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTenant = async () => {
      try {
        const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/${tenantid}`);
        setTenant(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching tenant:', err);
        setError('Error fetching tenant.');
        setLoading(false);
      }
    };

    fetchTenant();
  }, [tenantid]);

  const handleSignatureClear = () => {
    signatureCanvasRef.current.clear();
  };

  const handleSignatureSubmit = async () => {
    const signatureDataUrl = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
    setSignature(signatureDataUrl);
    setShowSignatureModal(false);
    handleAction(tenant._id, 'start-tenancy', signatureDataUrl);
  };

  const handleAction = async (tenantId, actionType, signatureDataUrl = null) => {
    setActionLoading(true);
    setSuccessMessage('');
    try {
      if (actionType === 'start-tenancy') {
        await axios.post(`http://${process.env.REACT_APP_URL}/api/tenants/documents/${tenantId}/start-tenancy`, {
          signature: signatureDataUrl,
        });
      } else if (actionType === 'send-notice') {
        await axios.post(`http://${process.env.REACT_APP_URL}/api/tenants/documents/${tenantId}/send-notice`);
      } else if (actionType === 'end-tenancy') {
        await axios.post(`http://${process.env.REACT_APP_URL}/api/tenants/documents/${tenantId}/end-tenancy`);
      }

      setSuccessMessage(`${actionType.replace('-', ' ')} action completed successfully.`);
      setActionComplete(true);
    } catch (err) {
      console.error(`Error performing action: ${actionType}`, err);
      setError(`Failed to ${actionType.replace('-', ' ')}.`);
    } finally {
      setActionLoading(false);
    }
  };

  const handlePaymentUpdate = (index, status) => {
    setTenant(prevTenant => {
      const updatedPayments = [...prevTenant.payments];
      updatedPayments[index].status = status;
      return { ...prevTenant, payments: updatedPayments };
    });
  };

  const calculateRentDue = () => {
    if (!tenant) return;

    const startDate = new Date(tenant.tenancyStartDate);
    const today = new Date();
    let totalRentDue = 0;

    tenant.payments.forEach(payment => {
      const paymentDate = new Date(payment.dueDate);
      if (paymentDate <= today) {
        totalRentDue += payment.amount;
      }
    });

    if (proRata) {
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const remainingDays = (endOfMonth - today) / (1000 * 60 * 60 * 24);
      const dailyRent = tenant.rentAmount / endOfMonth.getDate();
      totalRentDue += dailyRent * remainingDays;
    }

    return totalRentDue.toFixed(2);
  };

  const downloadRentSchedule = () => {
    const ws = XLSX.utils.json_to_sheet(tenant.payments);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Rent Schedule");
    XLSX.writeFile(wb, `Rent_Schedule_${tenant.name}.xlsx`);
  };

  if (loading) return <Loading />;
  if (error) return <ErrorModal message={error} onClose={() => setError(null)} />;

  let date = new Date(tenant.tenancyStartDate);
  const formattedDate = formatDateWithOrdinal(date);

  return (
    <Main pageTitle={`${tenant.name}`}>
      <div className={`tenant-single-page container ${actionLoading ? 'greyed-out' : ''}`}>
        <div className="tenant-single-container">
          <h1>{tenant.name} {tenant.isActive ? 'is' : 'is not'} currently active</h1>
          <p>Passport Number: {tenant.passportNumber}</p>
          <p>Tenancy Start Date: {formattedDate}</p>
          <p>Total Rent Due: £{calculateRentDue()}</p>
          <label>
            <input
              type="checkbox"
              checked={proRata}
              className='mb-lg'
              onChange={() => setProRata(!proRata)}
            />
            Calculate pro-rata final rent due
          </label>
        </div>
        <div className="tenant-actions-container">
          <button className='mr-sm' onClick={() => setShowSignatureModal(true)}>Send Tenancy agreement</button>
          <button className='mr-sm' onClick={() => handleAction(tenant._id, 'send-notice')}>Send notice</button>
          {
            !tenant.tenancyEndDate ? (
              <button className='mr-sm' onClick={() => handleAction(tenant._id, 'end-tenancy')}>End Tenancy</button>
            ) : null
          }

          <button className='mr-sm' onClick={() => navigate('tenancy-agreement')}>View Tenancy Agreement</button>
          <button className='mr-sm' onClick={() => navigate('proof-documents')}>View Proof of ID</button>
          <button className='mr-sm' onClick={downloadRentSchedule}>Download Rent Schedule</button>
        </div>

        <RentScheduleTable payments={tenant.payments} tenantId={tenant._id} onPaymentUpdate={handlePaymentUpdate} />
      </div>

      {actionLoading && (
        <div className="loading-overlay">
          <ClipLoader color={"#123abc"} loading={true} size={150} />
        </div>
      )}

      {actionComplete && (
        <SuccessModal message={successMessage} onClose={() => setActionComplete(false)} />
      )}

      {showSignatureModal && (
        <div className="signature-modal-overlay">
          <div className="signature-modal">
            <h2>Sign the Tenancy Agreement</h2>
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            />
            <div className="signature-modal-actions">
              <button onClick={handleSignatureClear}>Clear</button>
              <button onClick={handleSignatureSubmit}>Submit</button>
            </div>
            <button className="close-modal" onClick={() => setShowSignatureModal(false)}>Close</button>
          </div>
        </div>
      )}
    </Main>
  );
};

export default TenantSingle;