import axios from 'axios';

class AuthService {
  login(credentials) {
    return axios.post(`http://${process.env.REACT_APP_URL}/api/users/login`, credentials)
      .then(response => {
        console.log(response);
        const token = response.data.token;
        const user = response.data.user;  // Extract user details from the response

        // Store token and user details in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));

        return user;  // Return user details
      });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  isAuthenticated() {
    return !!localStorage.getItem('token');
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  getToken() {
    return localStorage.getItem('token');
  }
}

export const getUser = AuthService.prototype.getUser.bind(AuthService);
export default new AuthService();