import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import '../assets/scss/Breadcrumbs.scss'; // Import the CSS for styling

const Breadcrumb = () => {
  const location = useLocation();
  const [breadcrumbData, setBreadcrumbData] = useState([]);

  useEffect(() => {
    const fetchBreadcrumbData = async () => {
      const pathnames = location.pathname.split('/').filter(x => x);
      const data = [];

      for (let i = 0; i < pathnames.length; i++) {
        const segment = pathnames[i];
        let name = segment;
        
        // Check if the segment looks like an object ID
        if (/^[0-9a-fA-F]{24}$/.test(segment)) {
          try {
            if (i === 1) {
              // Assume the first ID is a property ID
              const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/properties/${segment}`);
              
              name = response.data.nickname; // Assuming the response contains a `name` field
            } else if (i === 2) {
              // Assume the second ID is a tenant ID
              const response = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/${segment}`);
              name = response.data.name; // Assuming the response contains a `name` field
            }
          } catch (error) {
            console.error(`Error fetching data for segment ${segment}:`, error);
          }
        }

        data.push({ name, path: `/${pathnames.slice(0, i + 1).join('/')}` });
      }

      setBreadcrumbData(data);
    };

    fetchBreadcrumbData();
  }, [location]);

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {breadcrumbData.map((crumb, index) => {
          const isLast = index === breadcrumbData.length - 1;
          return isLast ? (
            <li key={crumb.path} className="breadcrumb-item active" aria-current="page">
              {crumb.name}
            </li>
          ) : (
            <li key={crumb.path} className="breadcrumb-item">
              <Link to={crumb.path}>{crumb.name}</Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;