import React, { useState, useEffect } from 'react';
import AuthService from '../services/AuthService';
import '../assets/scss/Login.scss';
import { useNavigate } from "react-router-dom";

const Login = ({ history }) => {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  useEffect(() => {
    console.log(process.env.REACT_APP_URL)
  })

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("being hit")
      await AuthService.login(credentials);
      navigate('/properties');
    } catch (err) {
      console.error(err);
    }
  };

  return (

    <div className='login-page'>
      <div className="login-page-container">
        <div className="image-half">

        </div>
        <div className="form-half">
          <div className="form-box">
            <h1>Diar Properties Ltd</h1>
            <h5>Your Property, Perfectly Managed</h5>
            <form onSubmit={handleSubmit} className='login-form'>
              <input type="text" name="email" placeholder="Email" onChange={handleChange} required />
              <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
              <button type="submit">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;