import React, { useState, useEffect } from 'react';
import '../assets/scss/Navbar.scss';
import Logo from '../assets/images/diar_logo.png';
import AuthService from '../services/AuthService';
import { useNavigate } from "react-router-dom";
import Loading from '../pages/Loading';

const Navbar = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is authenticated and load user data
        if (AuthService.isAuthenticated()) {
            const currentUser = AuthService.getUser();
            if (currentUser) {
                setUser(currentUser);
            }
        }
        setLoading(false);
    }, []);

    if (loading) {
        return <Loading />; // Show a loading state while user data is being loaded
    }

    return (
        <div className="navbar">
            <div className="navbar-container container">
                <div className="logo" onClick={() => {
                    if (AuthService.isAuthenticated()) {
                        navigate('/properties');
                    } else {
                        navigate('/login');
                    }
                }}>
                    <img src={Logo} width={100} alt='logo' />
                </div>
                <div>
                    {user ? (
                        <div className='navbar-user'>
                            <p>Hello, {user.name}</p>
                            <button onClick={() => {
                                AuthService.logout();
                                navigate('/login');
                            }}>Logout</button>
                        </div>
                    ) : (
                        <p>Not Logged in</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Navbar;