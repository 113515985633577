import React, { useEffect, useState } from "react";
import axios from "axios";
import Main from '../components/Main';
import { useParams } from 'react-router-dom';
import TenantIcon from '../assets/images/icons/tenant.svg';
import Plus from '../assets/images/icons/plus.svg';
import '../assets/scss/PropertySingle.scss';
import ClipLoader from 'react-spinners/ClipLoader'
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";

const PropertySingle = () => {
  const { id } = useParams(); // Extract the id from the URL
  const [property, setProperty] = useState(null);
  const [tenant, setTenant] = useState([]);
  const [previousTenants, setPreviousTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPropertyAndTenants = async () => {
      try {
        // Fetch property data
        const propertyResponse = await axios.get(`http://${process.env.REACT_APP_URL}/api/properties/${id}`);
        setProperty(propertyResponse.data);
        console.log(propertyResponse.data)

        // Fetch tenants' data
        const tenantsResponse = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/property/${id}`);
        setTenant(tenantsResponse.data);

        const previousTenantsResponse = await axios.get(`http://${process.env.REACT_APP_URL}/api/tenants/property/previousTenants/${id}`)
        setPreviousTenants(previousTenantsResponse.data)

        setLoading(false);
      } catch (err) {
        console.error('Error fetching property or tenants:', err);
        setError('Error fetching property or tenants');
        setLoading(false);
      }
    };

    fetchPropertyAndTenants();
  }, [id]);

  if (loading) {
    return (
      <Loading />
    )
  }
  if (error) return <div>{error}</div>;
  if (!property) return <div>Loading...</div>; // Additional check

  return (
    <Main pageTitle={property.nickname}>
      <div className="property-single-page container">
        <h1>{property.nickname}</h1>
        <p><strong>Address:</strong> {property.address1} {property.address2 && property.address2} {property.city} {property.postcode}</p>

        <div className="tenants-container box">
          <h2>Tenant</h2>
          {
            property.tenant ? (
              <>
                <div>Name: {tenant.name}</div>
                <div>Passport Number: {tenant.passportNumber}</div>

                <button onClick={() => navigate(`${tenant._id}`)} className="mt-lg mb-zero">View Tenant</button>
              </>
            ) : (
              <>
                <div className="mb-lg">This property is currently unoccupied</div>
                <button onClick={() => navigate('add-tenant')}>Add Tenant</button>
              </>
            )
          }
        </div>

        <div className="compliance-container box">
          <h2>Compliance</h2>
          <button className="mr-sm">Gas certificate</button>
          <button className="mr-sm">Fire Alarm Test</button>
          <button className="mr-sm">Boiler certificate</button>
        </div>

        <div className="previous-tenants box">
          <h2>Previous Tenants</h2>
          {previousTenants && previousTenants.length > 0 ? (
            <table className="tenant-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Tenancy End Date</th>
                  <th>Room Number</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {previousTenants.map((tenant, key) => (
                  <tr key={key}>
                    <td>
                      <a href={`/properties/${property._id}/${tenant._id}`}>{tenant.name}</a>
                    </td>
                    <td>{new Date(tenant.tenancyEndDate).toLocaleDateString('en-GB')}</td>
                    <td>{tenant.roomNumber}</td>
                    <td><a href={`mailto:${tenant.email}`}>{tenant.email}</a></td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No previous tenants available.</p>
          )}
        </div>

      </div>
    </Main>
  );
};

export default PropertySingle;